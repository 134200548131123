import { Table, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { TablePaginationConfig } from 'antd/lib';
import { Dayjs } from 'dayjs';
import { useContext, useEffect, useState } from 'react';
import { FormattedDate, FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import gdaApi from '../../../apis/GdaApi';
import ConstantLabel from '../../../components/ConstantLabel/ConstantLabel';
import CustomContext from '../../../contexts/CustomContext';
import { Page } from '../../../models/Elements';
import { AemetWeatherStation, CustomAuth, GrowingDegreeDay } from '../../../models/Entities';
import { PhaseType } from '../../../models/Types';
import alertService from '../../../services/AlertService';
import paginationService from '../../../services/TableService';

/**
 * Returns the gda component.
 * @returns the gda component.
 */
const GdaComponent: React.FC<Props> = (props) => {
    /*** HOOKS ***/

    const { aemetWeatherStations, aemetWeatherStationId } = props;
    const intl = useIntl();
    const context = useContext(CustomContext);
    const auth = context.auth as CustomAuth;
    const [gdasPage, setGdasPage] = useState<Page<GrowingDegreeDay>>();
    const [page, setPage] = useState<number>(0);
    const [sortOrder, setSortOrder] = useState<boolean>(false);
    const [sortField, setSortField] = useState<string>('measurementDate');
    const [loading, setLoading] = useState<'loading'>();

    /*** EFFECTS ***/

    useEffect(() => {
        const init = async () => {
            try {
                setLoading('loading');
                if (aemetWeatherStationId) {
                    const gdasPage = await gdaApi.list(
                        page,
                        paginationService.pageSize,
                        sortField,
                        sortOrder,
                        aemetWeatherStationId,
                        undefined,
                        undefined,
                        undefined
                    );
                    setGdasPage(gdasPage);
                }
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [auth.companyId, aemetWeatherStationId, intl, page, sortOrder, sortField]);

    /*** METHODS ***/

    const selectedAemetStation = aemetWeatherStations.find((i) => i.aemetWeatherStationId === aemetWeatherStationId);

    const handleTableChange = async (pagination: TablePaginationConfig, filters: any, sorter: any) => {
        setPage(pagination.current ? pagination.current - 1 : 0);
        sorter.field !== undefined && setSortField(sorter.field);
        setSortOrder(sorter.order === 'ascend');
    };

    /*** VISUAL ***/

    const items = gdasPage ? gdasPage.content : [];
    const columns: ColumnsType<GrowingDegreeDay> = [
        {
            title: <FormattedMessage id="gda.measurementDate" />,
            dataIndex: 'measurementDate',
            key: 'measurementDate',
            width: 120,
            align: 'center',
            sorter: true,
            defaultSortOrder: 'descend',
            render: (value: Dayjs, gda: GrowingDegreeDay) => value && <FormattedDate value={value as any} year="numeric" month="2-digit" day="2-digit" />
        },
        {
            title: <FormattedMessage id="gda.aemetWeatherStationId" />,
            dataIndex: 'aemetWeatherStationId',
            key: 'aemetWeatherStationId',
            render: (value: string, gda: GrowingDegreeDay) => selectedAemetStation?.name && selectedAemetStation.name
        },
        {
            title: <FormattedMessage id="gda.plague" />,
            dataIndex: 'plague',
            key: 'plague',
            align: 'center',
            width: 240,
            render: (value: string | undefined, gda: GrowingDegreeDay) => <ConstantLabel value={value} prefix="plague.type." />
        },
        {
            title: <FormattedMessage id="gda.result" />,
            dataIndex: 'result',
            key: 'result',
            align: 'right',
            width: 240,
            render: (value: number | undefined) => value !== undefined && <FormattedNumber value={value} minimumFractionDigits={1} maximumFractionDigits={1} />
        },
        {
            title: <FormattedMessage id="gda.generation" />,
            dataIndex: 'generation',
            key: 'generation',
            width: 240,
            align: 'right',
            render: (value: number | undefined, gda: GrowingDegreeDay) =>
                value !== undefined && <FormattedNumber value={value} minimumFractionDigits={0} maximumFractionDigits={0} />
        },
        {
            title: <FormattedMessage id="gda.phase" />,
            dataIndex: 'phase',
            key: 'phase',
            width: 240,
            align: 'center',
            render: (value: PhaseType | undefined, gda: GrowingDegreeDay) => <PhaseVariationComponent phase={value} />
        }
    ];

    return (
        <Table
            dataSource={items}
            columns={columns}
            pagination={paginationService.createPagination(gdasPage!)}
            rowKey="id"
            onChange={handleTableChange}
            sortDirections={['ascend', 'descend']}
            showSorterTooltip={false}
            loading={loading === 'loading'}
            className="table"
        />
    );
};
export default GdaComponent;

interface Props {
    aemetWeatherStationId: string | undefined;
    aemetWeatherStations: AemetWeatherStation[];
}

/**
 * Returns the tag corresponding to the gda phase
 * @param props the props
 * @returns  the icon
 */
const PhaseVariationComponent: React.FC<PhaseVariationProps> = (props) => {
    const { phase } = props;

    switch (phase) {
        case 'EGG':
            return (
                <Tag color="orange">
                    <ConstantLabel value={phase} prefix="gda.phase." />
                </Tag>
            );
        case 'L1_L3':
            return (
                <Tag color="red">
                    <ConstantLabel value={phase} prefix="gda.phase." />
                </Tag>
            );
        case 'L4_L6':
            return (
                <Tag color="blue">
                    <ConstantLabel value={phase} prefix="gda.phase." />
                </Tag>
            );
        case 'PUPA':
            return (
                <Tag color="purple">
                    <ConstantLabel value={phase} prefix="gda.phase." />
                </Tag>
            );
        case 'ADULT':
            return (
                <Tag color="green">
                    <ConstantLabel value={phase} prefix="gda.phase." />
                </Tag>
            );
        default:
            return <></>;
    }
};
interface PhaseVariationProps {
    phase: PhaseType | undefined;
}
