import Icon, {
    BankOutlined,
    BellFilled,
    CameraOutlined,
    CloseOutlined,
    CodeSandboxOutlined,
    HomeOutlined,
    LogoutOutlined,
    MenuOutlined,
    NotificationOutlined,
    SunOutlined,
    FundOutlined,
    TeamOutlined,
    UserOutlined
} from '@ant-design/icons';
import { App, Avatar, Badge, Breadcrumb, Button, Col, Divider, Drawer, Layout, Menu, Row, Space } from 'antd';
import { MenuItemType } from 'antd/es/menu/hooks/useItems';
import { Footer } from 'antd/lib/layout/layout';
import React, { PropsWithChildren, useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useNavigate } from 'react-router-dom';
import CustomContext from '../../contexts/CustomContext';
import { BreadcrumbItem } from '../../models/Elements';
import { MenuType } from '../../models/Types';
import { ReactComponent as LogoIcon } from '../../resources/images/agrisens-icon.svg';
import { ReactComponent as dashboardOneIcon } from '../../resources/images/dashboard1-icon.svg';
import { ReactComponent as plotsIcon } from '../../resources/images/plots-icon.svg';
import { ReactComponent as usersIcon } from '../../resources/images/users-icon.svg';
import alertService from '../../services/AlertService';
import authService from '../../services/AuthService';
import rolesService from '../../services/RolesService';
import './LayoutComponent.css';
import styles from './LayoutComponent.module.scss';
import useResponsiveLayout from './UseResponsiveLayout/UseResponsiveLayout';

const { Header, Sider, Content } = Layout;

const LayoutComponent: React.FC<Props> = (props) => {
    const { menu, path, children } = props;

    const intl = useIntl();
    const navigate = useNavigate();
    const { modal } = App.useApp();
    const [desktop] = useResponsiveLayout();
    const { auth, setAuth, notifications } = useContext(CustomContext);
    const [collapsed, setCollapsed] = useState(localStorage.getItem('collapsed') !== null && localStorage.getItem('collapsed') === 'true');
    const [notificationsNumber, setNotificationsNumber] = useState<number>();

    /*** EFFECTS ***/

    useEffect(() => {
        const init = async () => {
            try {
                if (auth && auth.initialized) {
                    setNotificationsNumber(notifications);
                }
            } catch (error) {
                alertService.displayError(error, intl);
            }
        };
        init();
    }, [intl, auth, notifications]);

    /*** METHODS ***/

    const signOut = () => {
        modal.confirm({
            title: intl.formatMessage({ id: 'layout.header.menu.signOut.title' }),
            icon: <LogoutOutlined />,
            onOk: async () => {
                await authService.signOut();
                setAuth(undefined);
                navigate('/');
            }
        });
    };

    /**
     * Collapses or expands the sidebar menu.
     * @param collapsed if the sidebar shuld be collapsed
     */
    const collapse = (collapsed: boolean) => {
        desktop ? localStorage.setItem('collapsed', collapsed.toString()) : localStorage.removeItem('collapsed');
        setCollapsed(collapsed);
    };

    /*** COMPONENTS ***/

    if (desktop) {
        return (
            <DesktopLayoutComponent
                menu={menu}
                children={children}
                collapse={collapse}
                collapsed={collapsed}
                notificationsNumber={notificationsNumber}
                path={path}
                signOut={signOut}
            />
        );
    } else {
        return (
            <MobileLayoutComponent
                menu={menu}
                children={children}
                collapse={collapse}
                collapsed={collapsed}
                notificationsNumber={notificationsNumber}
                signOut={signOut}
            />
        );
    }
};
export default LayoutComponent;

interface Props extends PropsWithChildren {
    title: React.ReactNode | string;
    menu: MenuType;
    path?: BreadcrumbItem[];
}

const DesktopLayoutComponent = (props: DesktopProps): React.ReactElement => {
    const { collapsed, menu, notificationsNumber, children, collapse, signOut, path } = props;

    const navigate = useNavigate();
    const context = useContext(CustomContext);

    const siderStyles = collapsed ? `${styles.sider} ${styles.collapsed}` : styles.sider;
    const isAdmin = rolesService.hasAnyRole(context.auth, ['ROLE_ADMIN']);

    return (
        <Layout className={styles.layout} hasSider>
            <Sider collapsible collapsed={collapsed} onCollapse={(value) => collapse(value)} className={siderStyles} theme="light">
                <Link to="/" className={styles.title}>
                    <LogoIcon className={styles.logo} /> {!collapsed && <span>AgriSens</span>}
                </Link>
                <MenuComponent menu={menu} desktop={true} />
            </Sider>
            <Layout>
                <Header className={styles.header}>
                    <Breadcrumb className={styles.breadcrumb}>
                        <Breadcrumb.Item key="dashboard">
                            <Link to="/">
                                <HomeOutlined />
                            </Link>
                        </Breadcrumb.Item>
                        {path?.map((p) => (
                            <Breadcrumb.Item key={p.path}>
                                <Link to={p.path}>{p.name} </Link>
                            </Breadcrumb.Item>
                        ))}
                    </Breadcrumb>
                    {!isAdmin && (
                        <Badge count={notificationsNumber} className={styles.notifications}>
                            <Link to="/notifications">
                                <BellFilled size={24} />
                            </Link>
                        </Badge>
                    )}
                    <Menu
                        items={[
                            {
                                icon: <Avatar icon={<UserOutlined />} className={styles.avatar} />,
                                key: 'menu',
                                children: [
                                    {
                                        key: 'user',
                                        label: <FormattedMessage id="layout.header.menu.user" />,
                                        icon: <UserOutlined />,
                                        onClick: () => navigate('/settings')
                                    },
                                    {
                                        key: 'signout',
                                        label: <FormattedMessage id="layout.header.menu.signOut" />,
                                        icon: <LogoutOutlined />,
                                        onClick: signOut
                                    }
                                ]
                            }
                        ]}
                        mode="horizontal"
                    />
                </Header>
                <Content className={styles.content}>
                    <div className={styles.main}>{children}</div>
                </Content>
                <Footer className={styles.footer}>
                    <Row>
                        <Col className={styles.links}>
                            <Space className={styles.links} size="large">
                                <Link to="/legal-notice" className={styles.link}>
                                    <FormattedMessage id="common.legalNotice" />
                                </Link>
                                <Link to="/privacy-policy" className={styles.link}>
                                    <FormattedMessage id="common.privacyPolicy" />
                                </Link>
                            </Space>
                        </Col>
                        <Col className={styles.copyright}>
                            <a href="https://agrisens.es" target="_blank" rel="noreferrer">
                                ©2025 AgriSens Technologies S.L.
                            </a>
                        </Col>
                    </Row>
                </Footer>
            </Layout>
        </Layout>
    );
};

interface DesktopProps {
    collapsed: boolean;
    menu: MenuType;
    notificationsNumber?: number;
    children: React.ReactNode;
    collapse: (collapse: boolean) => void;
    signOut: () => void;
    path?: BreadcrumbItem[];
}

const MobileLayoutComponent = (props: MobileProps): React.ReactElement => {
    const { collapsed, menu, notificationsNumber, children, collapse, signOut } = props;
    const multipanel: boolean = ['dashboard'].includes(menu);

    const context = useContext(CustomContext);
    const isAdmin = rolesService.hasAnyRole(context.auth, ['ROLE_ADMIN']);

    return (
        <Layout className={styles.mobileLayout}>
            <Header className={styles.header}>
                <div className={styles.headerLeft}>
                    <Link to="/" className={styles.title}>
                        <LogoIcon className={styles.logo} /> {!collapsed && <span>AgriSens</span>}
                    </Link>
                </div>
                <div className={styles.headerRight}>
                    {!isAdmin && (
                        <Badge count={notificationsNumber} className={styles.notifications}>
                            <Link to="/notifications">
                                <BellFilled />
                            </Link>
                        </Badge>
                    )}
                    <Button type="link" onClick={() => collapse(true)} icon={<MenuOutlined />} />
                </div>
            </Header>
            <Drawer
                title={
                    <div className={styles.topDrawer}>
                        <Link to="/" className={styles.title}>
                            <LogoIcon className={styles.logo} /> <span>AgriSens</span>
                        </Link>
                        <Button type="link" className={styles.drawerClose} onClick={() => collapse(false)} icon={<CloseOutlined />} />
                    </div>
                }
                placement="right"
                closable={false}
                open={collapsed}
                width="100%"
            >
                <MenuComponent menu={menu} desktop={false} signOut={signOut} />
            </Drawer>
            <Content className={styles.content}>
                <div className={`${styles.main} ${multipanel && styles.multipanel}`}>{children}</div>
            </Content>
            <Footer className={styles.footer}>
                <Row>
                    <Col className={styles.links}>
                        <Space size="large">
                            <Link to="/legal-notice" className={styles.link}>
                                <FormattedMessage id="common.legalNotice" />
                            </Link>
                            <Link to="/privacy-policy" className={styles.link}>
                                <FormattedMessage id="common.privacyPolicy" />
                            </Link>
                        </Space>
                    </Col>
                    <Col className={styles.copyright}>
                        <a href="https://agrisens.es" target="_blank" rel="noreferrer">
                            ©2025 AgriSens Technologies S.L.
                        </a>
                    </Col>
                </Row>
            </Footer>
        </Layout>
    );
};

interface MobileProps {
    collapsed: boolean;
    menu: MenuType;
    children: React.ReactNode;
    collapse: (collapse: boolean) => void;
    signOut: () => void;
    notificationsNumber?: number;
}

const MenuComponent: React.FC<MenuProps> = (props) => {
    const { menu, desktop, signOut } = props;
    const context = useContext(CustomContext);

    const menuItems: MenuItemType[] = [
        {
            key: 'dashboard',
            icon: <Icon component={dashboardOneIcon} className="icon" />,
            label: (
                <Link to="/">
                    <FormattedMessage id="layout.menu.dashboard" />
                </Link>
            ),
            disabled: rolesService.hasAnyRole(context.auth, ['ROLE_ADMIN'])
        },
        {
            key: 'plots',
            icon: <Icon component={plotsIcon} className="icon" />,
            label: (
                <Link to="/plots">
                    <FormattedMessage id="layout.menu.plots" />
                </Link>
            ),
            disabled: rolesService.hasAnyRole(context.auth, ['ROLE_ADMIN'])
        },
        {
            key: 'users',
            icon: <Icon component={usersIcon} className="icon" />,
            label: (
                <Link to="/users">
                    <FormattedMessage id="layout.menu.users" />
                </Link>
            ),
            disabled: !rolesService.hasAnyRole(context.auth, ['ROLE_COMPANY_ADMIN']) || rolesService.hasAnyRole(context.auth, ['ROLE_ADMIN'])
        },
        {
            key: 'traps',
            icon: <CodeSandboxOutlined />,
            label: (
                <Link to="/admin/traps">
                    <FormattedMessage id="layout.menu.traps" />
                </Link>
            ),
            disabled: !rolesService.hasAnyRole(context.auth, ['ROLE_ADMIN'])
        },
        {
            key: 'trapImages',
            icon: <CameraOutlined />,
            label: (
                <Link to="/admin/trap-images">
                    <FormattedMessage id="layout.menu.trapImages" />
                </Link>
            ),
            disabled: !rolesService.hasAnyRole(context.auth, ['ROLE_ADMIN'])
        },
        {
            key: 'gdas',
            icon: <FundOutlined className={styles.icon} />,
            label: (
                <Link to="/admin/gdas">
                    <FormattedMessage id="layout.menu.gdas" />
                </Link>
            ),
            disabled: !rolesService.hasAnyRole(context.auth, ['ROLE_ADMIN'])
        },
        {
            key: 'aemet',
            icon: <SunOutlined className={styles.icon} />,
            label: (
                <Link to="/admin/aemet">
                    <FormattedMessage id="layout.menu.aemet" />
                </Link>
            ),
            disabled: !rolesService.hasAnyRole(context.auth, ['ROLE_ADMIN'])
        },
        {
            key: 'companies',
            icon: <BankOutlined />,
            label: (
                <Link to="/admin/companies">
                    <FormattedMessage id="layout.menu.companies" />
                </Link>
            ),
            disabled: !rolesService.hasAnyRole(context.auth, ['ROLE_ADMIN'])
        },
        {
            key: 'admin-users',
            icon: <TeamOutlined />,
            label: (
                <Link to="/admin/users">
                    <FormattedMessage id="layout.menu.users" />
                </Link>
            ),
            disabled: !rolesService.hasAnyRole(context.auth, ['ROLE_ADMIN'])
        },
        {
            key: 'notifications',
            icon: <NotificationOutlined />,
            label: (
                <Link to="/notifications">
                    <FormattedMessage id="layout.menu.notifications" />
                </Link>
            ),
            disabled: rolesService.hasAnyRole(context.auth, ['ROLE_ADMIN'])
        },
        {
            key: 'divider',
            label: <Divider />,
            disabled: desktop
        },
        {
            key: 'settings',
            icon: <UserOutlined />,
            label: (
                <Link to="/settings">
                    <FormattedMessage id="layout.header.menu.user" />
                </Link>
            ),
            disabled: desktop
        },
        {
            key: 'signOut',
            icon: <LogoutOutlined />,
            label: <FormattedMessage id="layout.header.menu.signOut" />,
            disabled: desktop,
            onClick: signOut
        }
    ];
    const availableMenuItems = menuItems.filter((i) => !i.disabled);

    return <Menu mode="inline" selectedKeys={[menu]} items={availableMenuItems} className={styles.menu} />;
};

interface MenuProps extends PropsWithChildren {
    menu: MenuType;
    desktop?: boolean;
    signOut?: () => void;
}
