import * as React from 'react';
import { Route, Routes } from 'react-router-dom';
import AdminAemetMeasuresPage from '../../pages/AdminAemetMeasuresPage/AdminAemetMeasuresPage';
import AdminCompaniesPage from '../../pages/AdminCompaniesPage/AdminCompaniesPage';
import AdminCompanyPage from '../../pages/AdminCompaniesPage/AdminCompanyPage/AdminCompanyPage';
import AdminTrapImagePage from '../../pages/AdminTrapImagesPage/AdminTrapImagePage/AdminTrapImagePage';
import AdminTrapImagesPage from '../../pages/AdminTrapImagesPage/AdminTrapImagesPage';
import AdminTrapPage from '../../pages/AdminTrapsPage/AdminTrapPage/AdminTrapPage';
import AdminTrapsPage from '../../pages/AdminTrapsPage/AdminTrapsPage';
import AdminUserPage from '../../pages/AdminUsersPage/AdminUserPage/AdminUserPage';
import AdminUsersPage from '../../pages/AdminUsersPage/AdminUsersPage';
import InitPage from '../../pages/AuthsPage/InitPage/InitPage';
import SignInPage from '../../pages/AuthsPage/SignInPage/SignInPage';
import SignUpPage from '../../pages/AuthsPage/SignUpPage/SignUpPage';
import ContactPage from '../../pages/ContactPage/ContactPage';
import DashboardPage from '../../pages/DashboardPage/DashboardPage';
import ErrorPage from '../../pages/ErrorPage/ErrorPage';
import ForgotPasswordPage from '../../pages/ForgotPasswordPage/ForgotPasswordPage';
import NotificationsPage from '../../pages/NotificationsPage/NotificationsPage';
import PlotPage from '../../pages/PlotPage/PlotPage';
import PlotsPage from '../../pages/PlotsPage/PlotsPage';
import SettingsPage from '../../pages/SettingsPage/SettingsPage';
import LegalNoticePage from '../../pages/Static/LegalNoticePage/LegalNoticePage';
import PrivacyPolicyPage from '../../pages/Static/PrivacyPolicyPage/PrivacyPolicyPage';
import TrapPlotPage from '../../pages/TrapPlotPage/TrapPlotPage';
import UserPage from '../../pages/UsersPage/UserPage/UserPage';
import UsersPage from '../../pages/UsersPage/UsersPage';
import DefaultRoute from '../DefaultRoute/DefaultRoute';
import ProtectedRoute from '../ProtectedRoute/ProtectedRoute';
import AdminAemetMeasurePage from '../../pages/AdminAemetMeasuresPage/AdminAemetMeasurePage/AdminAemetMeasurePage';
import AdminGdasPage from '../../pages/AdminGdasPage/AdminGdasPage';
import AdminGdaPage from '../../pages/AdminGdasPage/AdminGdaPage/AdminGdaPage';

const RoutesComponent: React.FC = () => {
    return (
        <Routes>
            <Route element={<ProtectedRoute authorities={['ROLE_ADMIN']} />}>
                <Route path="/admin/traps" element={<AdminTrapsPage />} />
                <Route path="/admin/traps/:id" element={<AdminTrapPage />} />
                <Route path="/admin/aemet" element={<AdminAemetMeasuresPage />} />
                <Route path="/admin/aemet/:id" element={<AdminAemetMeasurePage />} />
                <Route path="/admin/gdas" element={<AdminGdasPage />} />
                <Route path="/admin/gdas/:id" element={<AdminGdaPage />} />
                <Route path="/admin/trap-images" element={<AdminTrapImagesPage />} />
                <Route path="/admin/trap-images/:id" element={<AdminTrapImagePage />} />
                <Route path="/admin/companies" element={<AdminCompaniesPage />} />
                <Route path="/admin/companies/:id" element={<AdminCompanyPage />} />
                <Route path="/admin/users" element={<AdminUsersPage />} />
                <Route path="/admin/users/:id" element={<AdminUserPage />} />
            </Route>

            <Route element={<ProtectedRoute authorities={['ROLE_COMPANY_ADMIN']} />}>
                <Route path="/users" element={<UsersPage />} />
                <Route path="/users/:id" element={<UserPage />} />
            </Route>

            <Route element={<ProtectedRoute authorities={['ROLE_COMPANY_USER']} redirectPath="/" />}>
                <Route path="/dashboard" element={<DashboardPage />} />
                <Route path="/plots" element={<PlotsPage />} />
                <Route path="/plots/:id" element={<PlotPage />} />
                <Route path="/plots/:plotId/traps/:id" element={<TrapPlotPage />} />
                <Route path="/notifications" element={<NotificationsPage />} />
                <Route path="/settings" element={<SettingsPage />} />
            </Route>

            <Route element={<ProtectedRoute redirectPath="/signin" />}>
                <Route path="/init" element={<InitPage />} />
            </Route>

            <Route path="/" element={<DefaultRoute />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/signin" element={<SignInPage />} />
            <Route path="/signup" element={<SignUpPage />} />
            <Route path="/forgot-password" element={<ForgotPasswordPage />} />
            <Route path="/error" element={<ErrorPage />} />
            <Route path="*" element={<ErrorPage />} />
            <Route path="/legal-notice" element={<LegalNoticePage />} />
            <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
        </Routes>
    );
};
export default RoutesComponent;
