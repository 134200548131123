import { ArrowLeftOutlined, DeleteOutlined, SaveOutlined } from '@ant-design/icons';
import { App, Button, Col, DatePicker, Form, InputNumber, Row, Select, Space, message } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useNavigate, useParams } from 'react-router-dom';
import aemetWeatherStationApi from '../../../apis/AemetWeatherStationApi';
import gdaApi from '../../../apis/GdaApi';
import ConstantLabel from '../../../components/ConstantLabel/ConstantLabel';
import LayoutComponent from '../../../components/LayoutComponent/LayoutComponent';
import { AemetWeatherStation, GrowingDegreeDay } from '../../../models/Entities';
import { phaseTypes, plagues } from '../../../models/Types';
import alertService from '../../../services/AlertService';
import stringService from '../../../services/StringService';
import styles from './AdminGdaPage.module.scss';

/**
 * Returns the admin gda page.
 * @returns the admin gda page.
 */
const AdminGdaPage: React.FC = () => {
    /*** HOOKS ***/

    const intl = useIntl();
    const { modal } = App.useApp();
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const params = useParams<ParamsType>();
    const [loading, setLoading] = useState<'initializing' | 'loading' | 'deleting'>();
    const [gda, setGda] = useState<GrowingDegreeDay>();
    const [aemetStations, setAemetStations] = useState<AemetWeatherStation[]>([]);

    /*** EFFECTS ***/

    useEffect(() => {
        const init = async () => {
            try {
                setLoading('initializing');

                // load gda
                let gda: GrowingDegreeDay;
                if (params.id === 'new') {
                    gda = {};
                } else {
                    gda = await gdaApi.get(+params.id!);
                }
                form.setFieldsValue(gda);
                setGda(gda);

                const aemetStationsPage = await aemetWeatherStationApi.list(0, 10000, 'name', true);
                setAemetStations(aemetStationsPage.content);
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [intl, params.id, form]);

    /*** METHODS ***/

    const save = useCallback(
        async (values: any) => {
            try {
                setLoading('loading');

                let updatedGda: GrowingDegreeDay = Object.assign({}, gda, values);

                if (updatedGda.id) {
                    updatedGda = await gdaApi.update(updatedGda);
                } else {
                    updatedGda = await gdaApi.create(updatedGda);
                }

                message.success(intl.formatMessage({ id: 'status.saved' }));
                setGda(updatedGda);
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        },
        [intl, gda]
    );

    const remove = async () => {
        modal.confirm({
            title: intl.formatMessage({ id: 'gdas.deleteModal.title' }),
            okButtonProps: { loading: loading === 'deleting', danger: true },
            onOk: async () => {
                try {
                    setLoading('deleting');

                    await gdaApi.delete(gda?.id!);
                    message.success(intl.formatMessage({ id: 'status.deleted' }));

                    navigate('/admin/gdas');
                } catch (error) {
                    alertService.displayError(error, intl);
                } finally {
                    setLoading(undefined);
                }
            }
        });
    };

    /*** VISUAL ***/

    const plagueOptions = plagues.map((p) => (
        <Select.Option key={p} value={p}>
            <ConstantLabel value={p} prefix="plague.type." />
        </Select.Option>
    ));

    const phaseOptions = phaseTypes.map((pt) => (
        <Select.Option key={pt} value={pt}>
            <ConstantLabel value={pt} prefix="gda.phase." />
        </Select.Option>
    ));

    const aemetStationOptions = aemetStations.map((s) => (
        <Select.Option key={s.aemetWeatherStationId} value={s.aemetWeatherStationId}>
            {s.name}
        </Select.Option>
    ));

    return (
        <LayoutComponent
            title={<FormattedMessage id="gdas.title" />}
            menu="gdas"
            path={
                gda && gda.id
                    ? [
                          { path: '/admin/gdas', name: <FormattedMessage id="gdas.title" /> },
                          { path: `/admin/gdas/${gda.id}`, name: gda?.id }
                      ]
                    : [{ path: '/admin/gdas', name: <FormattedMessage id="gdas.title" /> }]
            }
        >
            <Form form={form} onFinish={save} colon={false} layout="vertical">
                <Row gutter={[12, 12]}>
                    <Col span={24} lg={8}>
                        <Form.Item
                            label={<FormattedMessage id="gda.measurementDate" />}
                            name="measurementDate"
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <DatePicker className={styles.date} size="large" format="DD/MM/YYYY" />
                        </Form.Item>
                    </Col>
                    <Col span={24} lg={8}>
                        <Form.Item
                            label={<FormattedMessage id="gda.aemetWeatherStationId" />}
                            name="aemetWeatherStationId"
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <Select
                                size="large"
                                showSearch
                                filterOption={stringService.filterOptions}
                                placeholder={<FormattedMessage id="gda.aemetWeatherStationId" />}
                                allowClear
                            >
                                {aemetStationOptions}
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={24} lg={8}>
                        <Form.Item
                            label={<FormattedMessage id="gda.plague" />}
                            name="plague"
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <Select
                                size="large"
                                showSearch
                                filterOption={stringService.filterOptions}
                                placeholder={<FormattedMessage id="gda.plague" />}
                                allowClear
                            >
                                {plagueOptions}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[12, 12]}>
                    <Col span={24} lg={4}>
                        <Form.Item label={<FormattedMessage id="gda.result" />} name="result">
                            <InputNumber size="large" className={styles.inputNumber} />
                        </Form.Item>
                    </Col>

                    <Col span={24} lg={4}>
                        <Form.Item label={<FormattedMessage id="gda.generation" />} name="generation">
                            <InputNumber size="large" className={styles.inputNumber} />
                        </Form.Item>
                    </Col>
                    <Col span={24} lg={8}>
                        <Form.Item
                            label={<FormattedMessage id="gda.phase" />}
                            name="phase"
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <Select
                                size="large"
                                showSearch
                                filterOption={stringService.filterOptions}
                                placeholder={<FormattedMessage id="gda.phase" />}
                                allowClear
                            >
                                {phaseOptions}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={[24, 24]} className="buttons">
                    <Col span={24}>
                        <Space>
                            <Link to="/admin/gdas">
                                <Button size="large" icon={<ArrowLeftOutlined />}></Button>
                            </Link>
                            <Button type="primary" htmlType="submit" size="large" loading={loading === 'loading'} icon={<SaveOutlined />}>
                                <FormattedMessage id="button.save" tagName="span" />
                            </Button>
                            {gda && gda.id && <Button danger size="large" onClick={remove} icon={<DeleteOutlined />}></Button>}
                        </Space>
                    </Col>
                </Row>
            </Form>
        </LayoutComponent>
    );
};
export default AdminGdaPage;
type ParamsType = { id: string };
