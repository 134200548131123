import { Form, Table, Tooltip } from 'antd';
import { Button, Col, Row, Select, Tag } from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import React, { useEffect, useState } from 'react';
import { FormattedDate, FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import gdaApi from '../../apis/GdaApi';
import ConstantLabel from '../../components/ConstantLabel/ConstantLabel';
import { Page } from '../../models/Elements';
import { AemetWeatherStation, GrowingDegreeDay } from '../../models/Entities';
import { PhaseType } from '../../models/Types';
import alertService from '../../services/AlertService';
import { Dayjs } from 'dayjs';
import LayoutComponent from '../../components/LayoutComponent/LayoutComponent';
import { Link } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import stringService from '../../services/StringService';
import useResponsiveLayout from '../../components/LayoutComponent/UseResponsiveLayout/UseResponsiveLayout';
import Search from 'antd/es/input/Search';
import aemetWeatherStationApi from '../../apis/AemetWeatherStationApi';
import styles from './AdminGdasPage.module.scss';
import paginationService from '../../services/TableService';

/**
 * Returns the admin gdas page.
 * @returns the admin gdas page.
 */

const AdminGdasPage: React.FC = () => {
    /*** HOOKS ***/

    const intl = useIntl();
    const [desktop] = useResponsiveLayout();
    const [form] = Form.useForm();
    const [filter, setFilter] = useState<Filter>({});
    const [page, setPage] = useState<number>(0);
    const [sortField, setSortField] = useState<string>('measurementDate');
    const [sortOrder, setSortOrder] = useState<boolean>(false);
    const [loading, setLoading] = useState<'loading'>();
    const [aemetStations, setAemetStations] = useState<AemetWeatherStation[]>([]);
    const [gdasPage, setGdasPage] = useState<Page<GrowingDegreeDay>>();

    /*** EFFECTS ***/

    useEffect(() => {
        const init = async () => {
            try {
                setLoading('loading');
                const gdasPage = await gdaApi.list(
                    page,
                    paginationService.pageSize,
                    sortField,
                    sortOrder,
                    filter.aemetStationId,
                    undefined,
                    undefined,
                    undefined,
                    filter.searchText
                );
                setGdasPage(gdasPage);
                const aemetStationsPage = await aemetWeatherStationApi.list(0, 10000, 'name', true);
                setAemetStations(aemetStationsPage.content);
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [filter.aemetStationId, filter.searchText, intl, page, sortField, sortOrder]);

    /*** METHODS ***/

    const handleTableChange = async (pagination: TablePaginationConfig, filters: any, sorter: any) => {
        setPage(pagination.current ? pagination.current - 1 : 0);
        sorter.field !== undefined && setSortField(sorter.field);
        setSortOrder(sorter.order === 'ascend');
    };

    const filterGdas = async (values: any) => {
        const filter: Filter = {
            searchText: values.searchText,
            aemetStationId: values.aemetStationId
        };
        setFilter(filter);
        setPage(0);
    };

    /*** VISUAL ***/

    const aemetStationOptions = aemetStations.map((s) => (
        <Select.Option key={s.aemetWeatherStationId} value={s.aemetWeatherStationId}>
            {s.name}
        </Select.Option>
    ));

    const items = gdasPage ? gdasPage.content : [];
    const columns: ColumnsType<GrowingDegreeDay> = [
        {
            title: <FormattedMessage id="gda.measurementDate" />,
            dataIndex: 'measurementDate',
            key: 'measurementDate',
            width: 120,
            align: 'center',
            sorter: true,
            defaultSortOrder: 'descend',
            render: (value: Dayjs, gda: GrowingDegreeDay) => (
                <Link to={`/admin/gdas/${gda.id}`}>{value && <FormattedDate value={value as any} year="numeric" month="2-digit" day="2-digit" />}</Link>
            )
        },
        {
            title: <FormattedMessage id="gda.aemetWeatherStationId" />,
            dataIndex: 'aemetWeatherStationId',
            key: 'aemetWeatherStationId',
            render: (value: string, gda: GrowingDegreeDay) => (
                <Link to={`/admin/gdas/${gda.id}`}>{aemetStations.find((i) => i.aemetWeatherStationId === value)?.name}</Link>
            )
        },
        {
            title: <FormattedMessage id="gda.plague" />,
            dataIndex: 'plague',
            key: 'plague',
            align: 'center',
            width: 300,
            render: (value: string | undefined, gda: GrowingDegreeDay) => (
                <Link to={`/admin/gdas/${gda.id}`}>
                    <ConstantLabel value={value} prefix="plague.type." />
                </Link>
            )
        },
        {
            title: <FormattedMessage id="gda.result" />,
            dataIndex: 'result',
            key: 'result',
            align: 'right',
            width: 200,
            render: (value: number | undefined, gda: GrowingDegreeDay) => (
                <Link to={`/admin/gdas/${gda.id}`}>
                    {value !== undefined && <FormattedNumber value={value} minimumFractionDigits={1} maximumFractionDigits={1} />}
                </Link>
            )
        },
        {
            title: <FormattedMessage id="gda.generation" />,
            dataIndex: 'generation',
            key: 'generation',
            width: 200,
            align: 'right',
            render: (value: number | undefined, gda: GrowingDegreeDay) => (
                <Link to={`/admin/gdas/${gda.id}`}>
                    {' '}
                    {value !== undefined && <FormattedNumber value={value} minimumFractionDigits={0} maximumFractionDigits={0} />}
                </Link>
            )
        },
        {
            title: <FormattedMessage id="gda.phase" />,
            dataIndex: 'phase',
            key: 'phase',
            width: 220,
            align: 'center',
            render: (value: PhaseType | undefined, gda: GrowingDegreeDay) => (
                <Link to={`/admin/gdas/${gda.id}`}>
                    <PhaseVariationComponent phase={value} />
                </Link>
            )
        }
    ];

    return (
        <LayoutComponent title={<FormattedMessage id="gdas.title" />} menu="gdas" path={[{ path: '/admin/gdas', name: <FormattedMessage id="gdas.title" /> }]}>
            <Form form={form} onFinish={filterGdas} colon={false} layout="vertical" requiredMark={false}>
                <Row gutter={[24, 24]}>
                    <Col span={4} lg={12}>
                        <Form.Item name="searchText">
                            <Search
                                placeholder={intl.formatMessage({
                                    id: 'gdas.search'
                                })}
                                size="large"
                                allowClear
                                onSearch={form.submit}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={4} lg={6}>
                        <Form.Item name="aemetStationId">
                            <Select
                                size="large"
                                showSearch
                                filterOption={stringService.filterOptions}
                                placeholder={<FormattedMessage id="aemet.aemetStation" />}
                                className={styles.select}
                                onSelect={form.submit}
                                allowClear
                            >
                                {aemetStationOptions}
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={4} lg={6} className="table-buttons">
                        <Link to="/admin/gdas/new">
                            <Tooltip title={<FormattedMessage id="aemet.new.tooltip" />}>
                                <Button type="primary" size="large" icon={<PlusOutlined />}>
                                    {desktop && <FormattedMessage id="button.add" tagName="span" />}
                                </Button>
                            </Tooltip>
                        </Link>
                    </Col>
                </Row>
            </Form>

            <Table
                dataSource={items}
                columns={columns}
                pagination={paginationService.createPagination(gdasPage!)}
                rowKey="id"
                onChange={handleTableChange}
                sortDirections={['ascend', 'descend']}
                showSorterTooltip={false}
                loading={loading === 'loading'}
                className="table"
            />
        </LayoutComponent>
    );
};
export default AdminGdasPage;

/**
 * Returns the tag corresponding to the gda phase
 * @param props the props
 * @returns  the icon
 */
const PhaseVariationComponent: React.FC<PhaseVariationProps> = (props) => {
    const { phase } = props;

    switch (phase) {
        case 'EGG':
            return (
                <Tag color="orange">
                    <ConstantLabel value={phase} prefix="gda.phase." />
                </Tag>
            );
        case 'L1_L3':
            return (
                <Tag color="red">
                    <ConstantLabel value={phase} prefix="gda.phase." />
                </Tag>
            );
        case 'L4_L6':
            return (
                <Tag color="blue">
                    <ConstantLabel value={phase} prefix="gda.phase." />
                </Tag>
            );
        case 'PUPA':
            return (
                <Tag color="purple">
                    <ConstantLabel value={phase} prefix="gda.phase." />
                </Tag>
            );
        case 'ADULT':
            return (
                <Tag color="green">
                    <ConstantLabel value={phase} prefix="gda.phase." />
                </Tag>
            );
        default:
            return <></>;
    }
};
interface PhaseVariationProps {
    phase: PhaseType | undefined;
}

interface Filter {
    searchText?: string | undefined;
    aemetStationId?: string | undefined;
}
