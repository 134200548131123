import { Page } from '../models/Elements';
import { AemetMeasure } from '../models/Entities';
import dateService from '../services/DateService';
import { coreApiAxios } from './CustomAxios';

class AemetMeasureApi {
    list = async (
        page: number,
        size: number,
        sortField: string,
        sortOrder: boolean,
        aemetWeatherStationId?: string,
        searchText?: string
    ): Promise<Page<AemetMeasure>> => {
        const response = await coreApiAxios.get<Page<AemetMeasure>>('/aemet/measures', {
            params: { page, size, sortField, sortOrder, aemetWeatherStationId, searchText }
        });
        response.data.sort.field = sortField;
        response.data.sort.order = sortOrder;
        response.data.content.forEach((aemetMeasure) => this.loadDates(aemetMeasure));

        return response.data;
    };

    get = async (aemetMeasureId: number): Promise<AemetMeasure> => {
        const response = await coreApiAxios.get<AemetMeasure>(`/aemet/measures/${aemetMeasureId}`);
        this.loadDates(response.data);

        return response.data;
    };

    create = async (aemetMeasure: AemetMeasure): Promise<AemetMeasure> => {
        const response = await coreApiAxios.post<AemetMeasure>(`/aemet/measures`, aemetMeasure);
        this.loadDates(response.data);

        return response.data;
    };

    update = async (aemetMeasure: AemetMeasure): Promise<AemetMeasure> => {
        const response = await coreApiAxios.put<AemetMeasure>(`/aemet/measures/${aemetMeasure.id}`, aemetMeasure);
        this.loadDates(response.data);

        return response.data;
    };

    delete = async (aemetMeasureId: number): Promise<void> => {
        const response = await coreApiAxios.delete<void>(`/aemet/measures/${aemetMeasureId}`);

        return response.data;
    };

    /**
     * Loads the dates of a aemetMeasure.
     * @param aemetMeasure - the aemetMeasure
     */
    private loadDates(aemetMeasure: AemetMeasure) {
        aemetMeasure.measurementDate = dateService.transformDate(aemetMeasure.measurementDate)!;
        aemetMeasure.audit!.created = dateService.transformDate(aemetMeasure.audit!.created)!;
        aemetMeasure.audit!.updated = dateService.transformDate(aemetMeasure.audit!.updated)!;
    }
}

const aemetMeasureApi: AemetMeasureApi = new AemetMeasureApi();
export default aemetMeasureApi;
