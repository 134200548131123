import { Page } from '../models/Elements';
import { GrowingDegreeDay } from '../models/Entities';
import dateService from '../services/DateService';
import { coreApiAxios } from './CustomAxios';
import { PhaseType, PlagueType } from '../models/Types';

class GdaApi {
    list = async (
        page: number,
        size: number,
        sortField: string,
        sortOrder: boolean,
        aemetWeatherStationId?: string,
        plague?: PlagueType,
        generation?: number,
        phase?: PhaseType,
        searchText?: string
    ): Promise<Page<GrowingDegreeDay>> => {
        const response = await coreApiAxios.get<Page<GrowingDegreeDay>>('/growing-degree-days', {
            params: { page, size, sortField, sortOrder, aemetWeatherStationId, plague, generation, phase, searchText }
        });
        response.data.sort.field = sortField;
        response.data.sort.order = sortOrder;
        response.data.content.forEach((gda) => this.loadDates(gda));

        return response.data;
    };

    get = async (id: number): Promise<GrowingDegreeDay> => {
        const response = await coreApiAxios.get<GrowingDegreeDay>(`/growing-degree-days/${id}`);
        this.loadDates(response.data);

        return response.data;
    };

    create = async (gda: GrowingDegreeDay): Promise<GrowingDegreeDay> => {
        const response = await coreApiAxios.post<GrowingDegreeDay>(`/growing-degree-days`, gda);
        this.loadDates(response.data);

        return response.data;
    };

    update = async (gda: GrowingDegreeDay): Promise<GrowingDegreeDay> => {
        const response = await coreApiAxios.put<GrowingDegreeDay>(`/growing-degree-days/${gda.id}`, gda);
        this.loadDates(response.data);

        return response.data;
    };

    delete = async (id: number): Promise<void> => {
        const response = await coreApiAxios.delete<void>(`/growing-degree-days/${id}`);

        return response.data;
    };
    /**
     * Loads the dates of a gda.
     * @param gda - the gda
     */
    private loadDates(gda: GrowingDegreeDay) {
        gda.measurementDate = dateService.transformDate(gda.measurementDate)!;
        gda.audit!.created = dateService.transformDate(gda.audit!.created)!;
        gda.audit!.updated = dateService.transformDate(gda.audit!.updated)!;
    }
}

const gdaApi: GdaApi = new GdaApi();
export default gdaApi;
