import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Row, Table, Tooltip } from 'antd';
import Search from 'antd/es/input/Search';
import { Select } from 'antd/lib';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import React, { useEffect, useState } from 'react';
import { FormattedDate, FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import aemetWeatherStationApi from '../../apis/AemetWeatherStationApi';
import aemetMeasureApi from '../../apis/AemetMeasureApi';
import LayoutComponent from '../../components/LayoutComponent/LayoutComponent';
import useResponsiveLayout from '../../components/LayoutComponent/UseResponsiveLayout/UseResponsiveLayout';
import { Page } from '../../models/Elements';
import { AemetMeasure, AemetWeatherStation } from '../../models/Entities';
import alertService from '../../services/AlertService';
import stringService from '../../services/StringService';
import paginationService from '../../services/TableService';
import styles from './AdminAemetMeasuresPage.module.scss';

/**
 * Returns the admin aemet measurespage.
 * @returns the admin aemet measures page.
 */

const AdminAemetMeasuresPage: React.FC = () => {
    /***HOOKS */
    const intl = useIntl();
    const [desktop] = useResponsiveLayout();
    const [form] = Form.useForm();
    const [filter, setFilter] = useState<Filter>({});
    const [adminAemetMeasuresPage, setAdminAemetMeasuresPage] = useState<Page<AemetMeasure>>();
    const [aemetStations, setAemetStations] = useState<AemetWeatherStation[]>([]);
    const [page, setPage] = useState<number>(0);
    const [sortField, setSortField] = useState<string>('name');
    const [sortOrder, setSortOrder] = useState<boolean>(true);
    const [loading, setLoading] = useState<'loading'>();

    //list aemet data
    useEffect(() => {
        const init = async () => {
            try {
                setLoading('loading');
                const adminAemetMeasuresPage = await aemetMeasureApi.list(
                    page,
                    paginationService.pageSize,
                    sortField,
                    sortOrder,
                    filter.aemetStationId,
                    filter.searchText
                );
                setAdminAemetMeasuresPage(adminAemetMeasuresPage);
                const aemetStationsPage = await aemetWeatherStationApi.list(0, 10000, 'name', true);
                setAemetStations(aemetStationsPage.content);
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [intl, page, sortField, sortOrder, filter.aemetStationId, filter.searchText]);

    /*** METHODS ***/

    const handleTableChange = async (pagination: TablePaginationConfig, filters: any, sorter: any) => {
        setPage(pagination.current ? pagination.current - 1 : 0);
        sorter.field !== undefined && setSortField(sorter.field);
        setSortOrder(sorter.order === 'ascend');
    };

    const filterAemet = async (values: any) => {
        const filter: Filter = {
            searchText: values.searchText,
            aemetStationId: values.aemetStationId
        };
        setFilter(filter);
        setPage(0);
    };

    /*** VISUAL ***/

    const aemetStationOptions = aemetStations.map((s) => (
        <Select.Option key={s.aemetWeatherStationId} value={s.aemetWeatherStationId}>
            {s.name}
        </Select.Option>
    ));

    const items = adminAemetMeasuresPage ? adminAemetMeasuresPage.content : [];

    const columns: ColumnsType<AemetMeasure> = [
        {
            title: <FormattedMessage id="aemetMeasure.measurementDate" />,
            dataIndex: 'measurementDate',
            key: 'measurementDate',
            width: 160,
            align: 'center',
            sorter: true,
            defaultSortOrder: 'descend',
            render: (value: string | undefined, aemetMeasure: AemetMeasure) => (
                <Link to={`/admin/aemet/${aemetMeasure.id}`}>
                    <FormattedDate value={value as any} year="numeric" month="2-digit" day="2-digit" />
                </Link>
            )
        },
        {
            title: <FormattedMessage id="aemetMeasure.aemetWeatherStationId" />,
            dataIndex: 'aemetWeatherStationId',
            key: 'aemetWeatherStationId',
            width: 140,
            sorter: true,
            render: (value: string | undefined, aemetMeasure: AemetMeasure) => <Link to={`/admin/aemet/${aemetMeasure.id}`}>{value}</Link>
        },
        {
            title: <FormattedMessage id="aemetMeasure.name" />,
            dataIndex: 'name',
            key: 'name',
            width: 160,
            sorter: true,
            render: (value: string | undefined, aemetMeasure: AemetMeasure) => <Link to={`/admin/aemet/${aemetMeasure.id}`}>{value}</Link>
        },
        {
            title: <FormattedMessage id="aemetMeasure.province" />,
            dataIndex: 'province',
            key: 'province',
            width: 180,
            sorter: true,
            render: (value: string | undefined, aemetMeasure: AemetMeasure) => <Link to={`/admin/aemet/${aemetMeasure.id}`}>{value}</Link>
        },
        {
            title: <FormattedMessage id="aemetMeasure.altitude" />,
            dataIndex: 'altitude',
            key: 'altitude',
            width: 140,
            align: 'right',
            sorter: true,
            render: (value: number | undefined, aemetMeasure: AemetMeasure) => <Link to={`/admin/aemet/${aemetMeasure.id}`}>{value}</Link>
        },
        {
            title: <FormattedMessage id="aemetMeasure.avgTemp" />,
            dataIndex: 'avgTemp',
            key: 'avgTemp',
            width: 220,
            sorter: true,
            align: 'right',
            render: (value: number | undefined, aemetMeasure: AemetMeasure) => (
                <Link to={`/admin/aemet/${aemetMeasure.id}`}>
                    {value && (
                        <FormattedNumber
                            value={value}
                            minimumFractionDigits={1}
                            maximumFractionDigits={1}
                            unit="celsius"
                            unitDisplay="short"
                            style={'unit' as any}
                        />
                    )}
                </Link>
            )
        },

        {
            title: <FormattedMessage id="aemetMeasure.minTemp" />,
            dataIndex: 'minTemp',
            key: 'minTemp',
            width: 220,
            align: 'right',
            sorter: true,
            render: (value: number | undefined, aemetMeasure: AemetMeasure) => (
                <Link to={`/admin/aemet/${aemetMeasure.id}`}>
                    {value && (
                        <FormattedNumber
                            value={value}
                            minimumFractionDigits={1}
                            maximumFractionDigits={1}
                            unit="celsius"
                            unitDisplay="short"
                            style={'unit' as any}
                        />
                    )}
                </Link>
            )
        },
        {
            title: <FormattedMessage id="aemetMeasure.maxTemp" />,
            dataIndex: 'maxTemp',
            key: 'maxTemp',
            width: 220,
            align: 'right',
            sorter: true,
            render: (value: number | undefined, aemetMeasure: AemetMeasure) => (
                <Link to={`/admin/aemet/${aemetMeasure.id}`}>
                    {value && (
                        <FormattedNumber
                            value={value}
                            minimumFractionDigits={1}
                            maximumFractionDigits={1}
                            unit="celsius"
                            unitDisplay="short"
                            style={'unit' as any}
                        />
                    )}
                </Link>
            )
        },
        {
            title: <FormattedMessage id="aemetMeasure.precipitation" />,
            dataIndex: 'precipitation',
            key: 'precipitation',
            width: 180,
            align: 'right',
            sorter: true,
            render: (value: number | undefined, aemetMeasure: AemetMeasure) => <Link to={`/admin/aemet/${aemetMeasure.id}`}>{value}</Link>
        },
        {
            title: <FormattedMessage id="aemetMeasure.avgHumidity" />,
            dataIndex: 'avgHumidity',
            key: 'avgHumidity',
            width: 250,
            align: 'right',
            sorter: true,
            render: (value: number | undefined, aemetMeasure: AemetMeasure) => (
                <Link to={`/admin/aemet/${aemetMeasure.id}`}>
                    {value && (
                        <FormattedNumber
                            value={value / 100}
                            minimumFractionDigits={1}
                            maximumFractionDigits={1}
                            unit="percent"
                            unitDisplay="short"
                            style={'percent' as any}
                        />
                    )}
                </Link>
            )
        },
        {
            title: <FormattedMessage id="aemetMeasure.maxHumidity" />,
            dataIndex: 'maxHumidity',
            key: 'maxHumidity',
            width: 250,
            align: 'right',
            sorter: true,
            render: (value: number | undefined, aemetMeasure: AemetMeasure) => (
                <Link to={`/admin/aemet/${aemetMeasure.id}`}>
                    {value && (
                        <FormattedNumber
                            value={value / 100}
                            minimumFractionDigits={1}
                            maximumFractionDigits={1}
                            unit="percent"
                            unitDisplay="short"
                            style={'percent' as any}
                        />
                    )}
                </Link>
            )
        },
        {
            title: <FormattedMessage id="aemetMeasure.minHumidity" />,
            dataIndex: 'minHumidity',
            key: 'minHumidity',
            width: 240,
            align: 'right',
            sorter: true,
            render: (value: number | undefined, aemetMeasure: AemetMeasure) => (
                <Link to={`/admin/aemet/${aemetMeasure.id}`}>
                    {value && (
                        <FormattedNumber
                            value={value / 100}
                            minimumFractionDigits={1}
                            maximumFractionDigits={1}
                            unit="percent"
                            unitDisplay="short"
                            style={'percent' as any}
                        />
                    )}
                </Link>
            )
        }
    ];

    return (
        <LayoutComponent
            title={<FormattedMessage id="aemet.title" />}
            menu="aemet"
            path={[{ path: '/admin/aemet', name: <FormattedMessage id="aemet.title" /> }]}
        >
            <Form form={form} onFinish={filterAemet} colon={false} layout="vertical" requiredMark={false}>
                <Row gutter={[24, 24]}>
                    <Col span={4} lg={12}>
                        <Form.Item name="searchText">
                            <Search
                                placeholder={intl.formatMessage({
                                    id: 'aemet.search'
                                })}
                                size="large"
                                allowClear
                                onSearch={form.submit}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={4} lg={6}>
                        <Form.Item name="aemetStationId">
                            <Select
                                size="large"
                                showSearch
                                filterOption={stringService.filterOptions}
                                placeholder={<FormattedMessage id="aemet.aemetStation" />}
                                className={styles.select}
                                onSelect={form.submit}
                                allowClear
                            >
                                {aemetStationOptions}
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={4} lg={6} className="table-buttons">
                        <Link to="/admin/aemet/new">
                            <Tooltip title={<FormattedMessage id="aemet.new.tooltip" />}>
                                <Button type="primary" size="large" icon={<PlusOutlined />}>
                                    {desktop && <FormattedMessage id="button.add" tagName="span" />}
                                </Button>
                            </Tooltip>
                        </Link>
                    </Col>
                </Row>
            </Form>

            <Table
                dataSource={items}
                columns={columns}
                pagination={paginationService.createPagination(adminAemetMeasuresPage!)}
                rowKey="id"
                onChange={handleTableChange}
                sortDirections={['ascend', 'descend']}
                showSorterTooltip={false}
                loading={loading === 'loading'}
                className="table"
            />
        </LayoutComponent>
    );
};
export default AdminAemetMeasuresPage;

interface Filter {
    searchText?: string | undefined;
    aemetStationId?: string | undefined;
}
