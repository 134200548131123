import { Dayjs } from 'dayjs';
import { Page } from '../models/Elements';
import { AemetMeasureStats, AemetWeatherStation } from '../models/Entities';
import { coreApiAxios } from './CustomAxios';
import { StatsDateType } from '../models/Types';
import dateService from '../services/DateService';

class AemetWeatherStationApi {
    list = async (page: number, size: number, sortField: string, sortOrder: boolean, searchText?: string): Promise<Page<AemetWeatherStation>> => {
        const response = await coreApiAxios.get<Page<AemetWeatherStation>>('/aemet/weather-stations', {
            params: { page, size, sortField, sortOrder, searchText }
        });
        response.data.sort.field = sortField;
        response.data.sort.order = sortOrder;

        return response.data;
    };

    listStats = async (startDate: Dayjs, endDate: Dayjs, statsDateType: StatsDateType, companyId?: number, plotId?: number): Promise<AemetMeasureStats[]> => {
        const response = await coreApiAxios.get<AemetMeasureStats[]>('/aemet/weather-stations/stats', {
            params: {
                startDate: startDate.format('YYYY/MM/DD').replaceAll('/', '-'),
                endDate: endDate.format('YYYY/MM/DD').replaceAll('/', '-'),
                statsDateType,
                companyId,
                plotId
            }
        });
        response.data.forEach((aemetMeasureStats) => this.loadDatesAemetMeasureStats(aemetMeasureStats));

        return response.data;
    };

    /**
     * Loads the dates of an AEMET measure stat.
     * @param aemetMeasureStats - the AEMET measure stat
     */
    private loadDatesAemetMeasureStats(aemetMeasureStats: AemetMeasureStats) {
        aemetMeasureStats.plot.audit!.created = dateService.transformDate(aemetMeasureStats.plot.audit!.created)!;
        aemetMeasureStats.plot.audit!.updated = dateService.transformDate(aemetMeasureStats.plot.audit!.updated)!;
        aemetMeasureStats.aemetMeasures.forEach((measure) => {
            measure.measurementDate = dateService.transformDate(measure.measurementDate)!;
            measure.audit!.created = dateService.transformDate(measure.audit!.created)!;
            measure.audit!.updated = dateService.transformDate(measure.audit!.updated)!;
        });
    }
}

const aemetWeatherStationApi: AemetWeatherStationApi = new AemetWeatherStationApi();
export default aemetWeatherStationApi;
