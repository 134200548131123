export const authorities = ['ROLE_ADMIN', 'ROLE_COMPANY_ADMIN', 'ROLE_COMPANY_USER'] as const;
export type AuthorityType = (typeof authorities)[number];

export const userTypes = ['ADMIN', 'COMPANY_ADMIN', 'COMPANY_USER'] as const;
export type UserType = (typeof userTypes)[number];

export const statuses = ['ENABLED', 'DISABLED'] as const;
export type StatusType = (typeof statuses)[number];

export const insectsVariationStates = ['LOW', 'MEDIUM', 'HIGH'] as const;
export type InsectsVariationStateType = (typeof insectsVariationStates)[number];

export const statsDates = ['DAY', 'MONTH'] as const;
export type StatsDateType = (typeof statsDates)[number];

export const menus = ['dashboard', 'users', 'settings', 'plots', 'notifications', 'traps', 'aemet', 'gdas', 'trapImages', 'companies'] as const;
export type MenuType = (typeof menus)[number];

export const notificationTypes = ['TRAP_PLOT_PHEROMONE_REPLACEMENT', 'TRAP_IMAGE_INSECTS_VARIATION', 'TRAP_IMAGE_INSECTS_COUNT'] as const;
export type NotificationType = (typeof notificationTypes)[number];

export const levels = ['LOW', 'MEDIUM', 'HIGH'] as const;
export type Level = (typeof levels)[number];

export const crops = ['WATERMELON', 'MELON', 'LETTUCE', 'EGGPLANT', 'CUCUMBER', 'PUMPKIN', 'BROCCOLI', 'TOMATO'] as const;
export type CropType = (typeof crops)[number];

export const plagues = ['TRIPS', 'S_EXIGUA', 'S_LITTORALIS', 'HELIOTIS', 'A_GAMMA', 'APHID', 'TUTA_ABSOLUTA'] as const;
export type PlagueType = (typeof plagues)[number];

export const farmings = ['GREENHOUSE', 'OPENFIELD'] as const;
export type FarmingType = (typeof farmings)[number];

export const phaseTypes = ['EGG', 'L1_L3', 'L4_L6', 'PUPA', 'ADULT'] as const;
export type PhaseType = (typeof phaseTypes)[number];
