import { ArrowLeftOutlined, DeleteOutlined, SaveOutlined } from '@ant-design/icons';
import { App, Button, Col, DatePicker, Form, Input, InputNumber, Row, Select, Space, message } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useNavigate, useParams } from 'react-router-dom';
import aemetMeasureApi from '../../../apis/AemetMeasureApi';
import LayoutComponent from '../../../components/LayoutComponent/LayoutComponent';
import { AemetMeasure, AemetWeatherStation } from '../../../models/Entities';
import alertService from '../../../services/AlertService';
import aemetWeatherStationApi from '../../../apis/AemetWeatherStationApi';
import stringService from '../../../services/StringService';
import styles from './AdminAemetMeasurePage.module.scss';

/**
 * Returns the admin aemet measure page.
 * @returns the admin aemet measure page.
 */
const AdminAemetMeasurePage: React.FC = () => {
    /*** HOOKS ***/

    const intl = useIntl();
    const { modal } = App.useApp();
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const params = useParams<ParamsType>();

    const [loading, setLoading] = useState<'initializing' | 'loading' | 'deleting'>();
    const [aemetMeasure, setAemetMeasure] = useState<AemetMeasure>();
    const [aemetStations, setAemetStations] = useState<AemetWeatherStation[]>([]);

    /*** EFFECTS ***/

    useEffect(() => {
        const init = async () => {
            try {
                setLoading('initializing');

                // load aemet measure
                let aemetMeasure: AemetMeasure;
                if (params.id === 'new') {
                    aemetMeasure = {};
                } else {
                    aemetMeasure = await aemetMeasureApi.get(+params.id!);
                }
                form.setFieldsValue(aemetMeasure);
                setAemetMeasure(aemetMeasure);

                const aemetStationsPage = await aemetWeatherStationApi.list(0, 10000, 'name', true);
                setAemetStations(aemetStationsPage.content);
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [intl, params.id, form]);

    /*** METHODS ***/

    const save = useCallback(
        async (values: any) => {
            try {
                setLoading('loading');

                let updatedAemetMeasure: AemetMeasure = Object.assign({}, aemetMeasure, values);
                if (updatedAemetMeasure.id) {
                    updatedAemetMeasure = await aemetMeasureApi.update(updatedAemetMeasure);
                } else {
                    updatedAemetMeasure = await aemetMeasureApi.create(updatedAemetMeasure);
                }

                message.success(intl.formatMessage({ id: 'status.saved' }));
                setAemetMeasure(updatedAemetMeasure);
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        },
        [intl, aemetMeasure]
    );

    const remove = async () => {
        modal.confirm({
            title: intl.formatMessage({ id: 'aemet.deleteModal.title' }),
            okButtonProps: { loading: loading === 'deleting', danger: true },
            onOk: async () => {
                try {
                    setLoading('deleting');

                    await aemetMeasureApi.delete(aemetMeasure?.id!);
                    message.success(intl.formatMessage({ id: 'status.deleted' }));

                    navigate('/admin/aemet');
                } catch (error) {
                    alertService.displayError(error, intl);
                } finally {
                    setLoading(undefined);
                }
            }
        });
    };

    /*** VISUAL ***/

    const aemetStationOptions = aemetStations.map((s) => (
        <Select.Option key={s.aemetWeatherStationId} value={s.name}>
            {s.name}
        </Select.Option>
    ));

    const aemetStationIdOptions = aemetStations.map((s) => (
        <Select.Option key={s.aemetWeatherStationId} value={s.aemetWeatherStationId}>
            {s.aemetWeatherStationId}
        </Select.Option>
    ));

    const aemetProvincesOptions = aemetStations
        .map((s) => s.province)
        .filter((value, index, self) => self.indexOf(value) === index)
        .sort(stringService.sort)
        .map((p) => (
            <Select.Option key={p} value={p}>
                {p}
            </Select.Option>
        ));

    return (
        <LayoutComponent
            title={<FormattedMessage id="aemet.title" />}
            menu="aemet"
            path={
                aemetMeasure && aemetMeasure.id
                    ? [
                          { path: '/admin/aemet', name: <FormattedMessage id="aemet.title" /> },
                          { path: `/admin/aemet/${aemetMeasure.id}`, name: aemetMeasure?.id }
                      ]
                    : [{ path: '/admin/aemet', name: <FormattedMessage id="aemet.title" /> }]
            }
        >
            <Form form={form} onFinish={save} colon={false} layout="vertical">
                <Row gutter={[12, 12]}>
                    <Col span={24} lg={6}>
                        <Form.Item
                            label={<FormattedMessage id="aemetMeasure.measurementDate" />}
                            name="measurementDate"
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <DatePicker className={styles.date} size="large" format="DD/MM/YYYY" />
                        </Form.Item>
                    </Col>
                    <Col span={24} lg={6}>
                        <Form.Item
                            label={<FormattedMessage id="aemetMeasure.aemetWeatherStationId" />}
                            name="aemetWeatherStationId"
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <Select
                                size="large"
                                showSearch
                                filterOption={stringService.filterOptions}
                                placeholder={<FormattedMessage id="aemet.aemetStation" />}
                                allowClear
                            >
                                {aemetStationIdOptions}
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={24} lg={6}>
                        <Form.Item
                            label={<FormattedMessage id="aemetMeasure.name" />}
                            name="name"
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <Select
                                size="large"
                                showSearch
                                filterOption={stringService.filterOptions}
                                placeholder={<FormattedMessage id="aemet.aemetStation" />}
                                allowClear
                            >
                                {aemetStationOptions}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24} lg={6}>
                        <Form.Item label={<FormattedMessage id="aemetMeasure.province" />} name="province">
                            <Select
                                size="large"
                                showSearch
                                filterOption={stringService.filterOptions}
                                placeholder={<FormattedMessage id="aemet.aemetStation" />}
                                allowClear
                            >
                                {aemetProvincesOptions}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[12, 12]}>
                    <Col span={24} lg={3}>
                        <Form.Item label={<FormattedMessage id="aemetMeasure.altitude" />} name="altitude">
                            <InputNumber size="large" maxLength={50} className={styles.inputNumber} />
                        </Form.Item>
                    </Col>
                    <Col span={24} lg={3}>
                        <Form.Item label={<FormattedMessage id="aemetMeasure.avgTemp" />} name="avgTemp">
                            <InputNumber suffix="°C" size="large" className={styles.inputNumber} />
                        </Form.Item>
                    </Col>

                    <Col span={24} lg={3}>
                        <Form.Item label={<FormattedMessage id="aemetMeasure.minTemp" />} name="minTemp">
                            <InputNumber suffix="°C" size="large" className={styles.inputNumber} />
                        </Form.Item>
                    </Col>
                    <Col span={24} lg={3}>
                        <Form.Item label={<FormattedMessage id="aemetMeasure.maxTemp" />} name="maxTemp">
                            <InputNumber suffix="°C" size="large" className={styles.inputNumber} />
                        </Form.Item>
                    </Col>
                    <Col span={24} lg={3}>
                        <Form.Item label={<FormattedMessage id="aemetMeasure.avgHumidity" />} name="avgHumidity">
                            <InputNumber suffix="%" size="large" maxLength={50} className={styles.inputNumber} />
                        </Form.Item>
                    </Col>
                    <Col span={24} lg={3}>
                        <Form.Item label={<FormattedMessage id="aemetMeasure.minHumidity" />} name="minHumidity">
                            <InputNumber suffix="%" size="large" maxLength={50} className={styles.inputNumber} />
                        </Form.Item>
                    </Col>
                    <Col span={24} lg={3}>
                        <Form.Item label={<FormattedMessage id="aemetMeasure.maxHumidity" />} name="maxHumidity">
                            <InputNumber suffix="%" size="large" maxLength={50} className={styles.inputNumber} />
                        </Form.Item>
                    </Col>
                    <Col span={24} lg={3}>
                        <Form.Item label={<FormattedMessage id="aemetMeasure.precipitation" />} name="precipitation">
                            <Input size="large" maxLength={50} className={styles.inputNumber} />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={[24, 24]} className="buttons">
                    <Col span={24}>
                        <Space>
                            <Link to="/admin/aemet">
                                <Button size="large" icon={<ArrowLeftOutlined />}></Button>
                            </Link>
                            <Button type="primary" htmlType="submit" size="large" loading={loading === 'loading'} icon={<SaveOutlined />}>
                                <FormattedMessage id="button.save" tagName="span" />
                            </Button>
                            {aemetMeasure && aemetMeasure.id && <Button danger size="large" onClick={remove} icon={<DeleteOutlined />}></Button>}
                        </Space>
                    </Col>
                </Row>
            </Form>
        </LayoutComponent>
    );
};
export default AdminAemetMeasurePage;
type ParamsType = { id: string };
